import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "react-toastify/dist/ReactToastify.css";
// import './styles/styles.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import { useContext, useEffect } from 'react';
import { ThemeContext } from './context/ThemeProvider';
import { ToastContainer } from 'react-toastify';


function App() {
  
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const importStyles = () => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = theme ? "./styles/dark-styles.css" : "./styles/styles.css";

      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    };

    const cleanup = importStyles();

    return () => {
      cleanup();
    };
  }, [theme]);


  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<LandingPage />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
